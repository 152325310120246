import React from 'react'
import { Link } from 'react-router-dom'
import logo from "../img/logo.png"

const Footer = () => {
  return (
      <div>
          <div class="flex flex-row justify-end items-start">
              
              <Link to="https://wa.link/39h77l">
                  <div className='bg-black shadow-lg rounded-full flex flex-col justify-center items-center w-24 h-24'>
                      <i class="lab la-whatsapp la-5x text-emerald-500"></i>
                  </div>
                        
                        </Link>
        
      </div>
          <div className="bg-emerald-950 flex lg:flex-row flex-col justify-around items-start text-white py-20 px-10 lg:space-y-0 space-y-20">
              <div id='mainObjectives'>
                  <img src={logo} alt="" />
                  <p className='font-medium text-white mt-7'>Trade Verse mission is to create the best trading <br /> experience for retail and institutional clients alike, allowing <br /> traders to focus more on their trading. Built by traders for traders <br /> (TSIQ) is dedicated to offering superior spreads, <br /> execution and service.</p>
                  <div className='flex flex-row justify-betwee items-center space-x-4 mt-10'>
                        <Link to="/login" className='bg-green-500 rounded-md flex flex-row justify-between items-center space-x-2 text-white py-2 px-4'>
                        <p className='hover:font-bold font-semibold'>App Store</p>
                        </Link>
                        <Link to="/login" className='bg-blue-500 rounded-md flex flex-row justify-between items-center space-x-2 text-white py-2 px-4'>
                        <p className='hover:font-bold font-semibold'>Google Play</p>
                      </Link>
                  </div>
              </div>
              <div id='company'>
                  <h1 className='text-xl font-semibold mb-7 text-white'>Company</h1>
                  <ul className="list-disc text-green-500">
                    <li className="mb-3">
                        <Link to="/company" className='mb-12 text-gray-400 hover:text-white'>Why Choose Us?</Link>
                    </li>
                    <li className="mb-3">
                        <Link to="/" className='mb-12 text-gray-400 hover:text-white'>Legal Documents</Link>
                    </li>
                    <li className="mb-3">
                        <Link to="/" className='mb-12 text-gray-400 hover:text-white'>Affiliate</Link>
                    </li>
                    <li className="mb-3">
                        <Link to="/" className='mb-12 text-gray-400 hover:text-white'>Insurance</Link>
                    </li>
                  </ul>
              </div>
              <div id='products'>
                  <h1 className='text-xl font-semibold mb-7 text-white'>Products</h1>
                  <ul className="list-disc text-green-500">
                    <li className="mb-3">
                        <Link to="/product" className='mb-12 text-gray-400 hover:text-white'>Indices CFDs</Link>
                    </li>
                    <li className="mb-3">
                        <Link to="/product" className='mb-12 text-gray-400 hover:text-white'>Bonds CFDs</Link>
                    </li>
                    <li className="mb-3">
                        <Link to="/product" className='mb-12 text-gray-400 hover:text-white'>Stocks CFDs</Link>
                    </li>
                  </ul>
              </div>
              <div id='more'>
                  <h1 className='text-xl font-semibold mb-7 text-white'>More</h1>
                  <ul className="list-disc text-green-500">
                    <li className="mb-3">
                        <Link to="/register" className='mb-12 text-gray-400 hover:text-white'>Register</Link>
                    </li>
                    <li className="mb-3">
                        <Link to="/login" className='mb-12 text-gray-400 hover:text-white'>Login</Link>
                    </li>
                    <li className="mb-3">
                        <Link to="/contact" className='mb-12 text-gray-400 hover:text-white'>Contact Us</Link>
                    </li>
                  </ul>
              </div>
          </div> 
          <div className='bg-emerald-950 flex flex-col justify-center items-center p-10'>
              <hr />
              <p className='text-white'>Trade Verse is authorized and regulated by the Seychelles Financial Services Authority (FSA) under Securities Dealer's license number SD018. Risk Warning: Trading Forex and CFDs involves a significant risk to your capital. You should only trade with experienced professionals who can help protect your funds. Forex and CFD trading may not be suitable for all investors, so we strongly advise seeking independent guidance from expert traders before engaging in such activities. To register with Trade Verse, you must be at least 18 years old, or of legal age in your jurisdiction. By creating an account, you confirm that you are doing so voluntarily and without solicitation from Trade Verse.

You should seek advice from an independent, suitably licensed financial advisor and ensure you have the appropriate risk tolerance, relevant experience, and knowledge before deciding to trade. Under no circumstances shall Trade Verse be held liable for any direct, indirect, special, consequential, or incidental damages of any kind. Cryptocurrency markets are unregulated and are not governed by any specific European regulatory framework (including MiFID). Therefore, when using our Cryptocurrency Trading Service, you will not be entitled to the protections provided to clients of MiFID-regulated investment services, such as access to the Cyprus Investor Compensation Fund (ICF), the Financial Services Compensation Scheme (FSCS), or the Financial Ombudsman Service for dispute resolution. Trading with Trade Verse, including following, copying, or replicating the trades of other traders, carries a high level of risk, even when following top-performing traders. These risks include the possibility of copying trades from inexperienced or unprofessional traders, or from traders whose financial goals or circumstances may differ from your own. Past performance of any Trade Verse Community Member is not a reliable indicator of future performance. Content shared on Trade Verse's social trading platform is generated by its community members and does not constitute advice or recommendations from Trade Verse – Your Social Investment Network. Copyright © 2006-2024 Trade Verse - Your Social Investment Network. All rights reserved.</p>
              <hr />
          </div>
          <div className='flex flex-col justify-between items-center p-8 bg-emerald-950'>
              <p className="text-white">© 2024 All Rights Reserved By <Link to="/" className="text-green-500 font-bold">Trade Verse</Link>.</p>
          </div>
    </div>
  )
}

export default Footer